.event-card {
    height: 100%; /* Set a fixed height for the card */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; /* Set the height as needed */
  }
  
  .square-icon {
    font-size: 10px;
    margin: 0 4px; /* Adjust the margin as needed */
  }

  /* ShowEvent.css (or your global styles) */
.event-list-card {
  flex: 1;
}

.slot-list-card {
  flex: 1;
}
