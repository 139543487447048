/* EventList.css */
body {
    font-family: 'Open Sans', sans-serif;
  }

.event-list-container {
    display: flex;
    flex-direction: row;
    align-items: stretch; /* Match the height of both columns */
    overflow-x: auto; /* Add horizontal scroll if necessary */
  }
  
  .slot-list-card {
    flex: 1;
    min-width: 300px; /* Adjust the width as needed */
    max-width: 40%; /* Limit the maximum width */
    margin-right: 10px;
  }
  
  .custom-card {
    width: 100%;
    max-width: 300px; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
  }
  
  .custom-card .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  /* Add any additional styles as needed */
  