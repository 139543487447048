/* Header Styles */

body {
  font-family: 'Open Sans', sans-serif;
}
.header-brand {
    display: block;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    width: 300px;
    height: 75px;
    background-color: #f7f7f7;
  }
  
  .custom-border {
    display:flexbox;
    border: 2px solid #95a5a6; /* Replace with your desired border color */
    padding: 10px; /* Add some padding to create space around the content */
    width: 25%;
  }

  .header-right {
    text-align: right;
    padding: 10px;
  }
  
  .header-center {
    text-align: center;
    padding: 10px;
  }
  
  /* Calendar Dropdown Styles */
  .calendar-toggle {
    cursor: pointer;
    font-size: 14px;
  }
  
  .calendar-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  /* Custom Calendar Styles */
  .custom-calendar {
    /* Define your custom calendar styles here */
  }
  
  .calendar-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* ... your other styles */
  }