.dropdown-container {
    display: inline-block;
    position: relative;
  }
  
  .dropdown-select {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    width: 200px; /* Adjust width as needed */
  }
  
  /* Optional: Add hover and focus styles */
  .dropdown-select:hover,
  .dropdown-select:focus {
    border-color: #3498db;
  }